.nav {
  // background-color: #feedba;
  background-color: #fff;
  width: 100%;
  padding: 0;
  margin: 0;
  opacity: 1;
  box-shadow: 0px 3px 6px #00000029;

  &.isUserLoggedIn {
    background-color: #fbc216;
    background-color: #fff;
    background-color: #feedba;
  }

  .navContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    width: 90vw;
    max-width: 1400px;
    margin: 0 auto;
  }

  .logoWrapper {
    max-width: 180px;
    @media all and (max-device-width: 480px) {
      max-width: 130px;
    }

    img {
      max-width: 100%;
    }
  }

  ul {
    list-style: none;
    display: flex;
    margin-bottom: 0;
    margin-top: 0;
    padding-right: 0;
    @media all and (max-device-width: 480px) {
      padding-right: 2px;
    }

    li {
      padding: 5px;
      @media all and (max-device-width: 480px) {
        padding: 2px;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }

  .loginBtn {
    padding: 3px 10px;
    border: 2px solid #fbc216;
    color: #fbc216;
    border-radius: 3px;
    transition: 0.4s;
    user-select: none;
    @media all and (max-device-width: 480px) {
      font-size: 10px;
    }

    &:hover {
      background-color: #fbc216;
      color: #fff;
      box-shadow: 0 1px 10px 1px rgba(#222, 0.25);
    }
  }

  .registerBtn {
    padding: 3px 10px;
    background-color: #fbc216;
    border: 2px solid #fbc216;
    color: #fff;
    border-radius: 3px;
    transition: 0.4s;
    user-select: none;
    @media all and (max-device-width: 480px) {
      font-size: 10px;
    }

    &:hover {
      background-color: #fbc216;
      color: #fff;
      box-shadow: 0 1px 10px 1px rgba(#222, 0.25);
    }
  }

  .logoutBtn {
    color: #1647fb;
    font-size: small;
  }

  .icons {
    color: #fbc216;
    width: 21px !important;
    height: auto;
    line-height: 1;

    @media all and (max-device-width: 480px) {
      // font-size: 10px;
    }
  }

  .notify {
    display: inline-block;
    padding: 0.25em 0.6em;
    font-size: 12px;
    @media all and (max-device-width: 480px) {
      font-size: 8px;
    }
    line-height: 1;
    text-align: center;
    font-weight: 300;
    border-radius: 4rem;
    color: #fff;
    background-color: #fa3434;
  }
  .rounded {
    display: flex;
    align-content: flex-start;
    justify-content: center;
    padding: 0.135em;

    @media all and (max-device-width: 480px) {
      padding: 0.35em;
    }

    border: 1px solid hsl(45, 97%, 54%) !important;
    // background-color: #fff;
  }
}
.widgetHeader {
  position: relative;

  a {
    width: 37px;
    height: 37px;

    @media all and (max-device-width: 480px) {
      width: 27px;
      height: 27px;
    }
  }

  .notify {
    position: absolute;
    top: -2px;
    right: 4px;
    @media all and (max-device-width: 480px) {
      top: -3px;
      right: 8px;
    }
  }
}
