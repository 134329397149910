.footer {
  background-color: #f5f4f1;
  border-top: 1px solid #eaeaea;
  width: 100%;
  height: 60px;
  padding: 0;
  margin: 0;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;

  .footerContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90vw;
    max-width: 1400px;
    margin: 0 auto;
  }

  .sectionOne {
    //padding: 20px;
    margin-top: 20px;
    flex: 1;
    padding-left: 0;

    @media screen and (max-width: 980px) {
      font-size: 12px;
    }

    a {
      max-width: 100%;
      &:not(:hover) {
        //color: #687580;
        color: #5076ff;
      }
    }
  }

  ul {
    list-style: none;
    display: flex;
    margin-bottom: 0;
    margin-top: 0;
    padding: 0;

    li {
      padding: 5px;

      &:last-child {
        padding-right: 0;
      }

      @media screen and (max-width: 980px) {
        font-size: 12px;
      }
    }
  }

  @media screen and (max-width: 410px) {
    display: block;
    width: 100vw;
    min-height: 60px;
    height: 100%;

    .sectionOne,
    & > ul {
      display: block;
      width: 100%;
    }

    & > ul {
      padding: 20px;
      padding-top: 0;

      li {
        padding: 0;
      }
    }
  }
}
